const DSZ_API = process.env.REACT_APP_API_URL_DSZ;
const RETAILER_BASE_API = process.env.REACT_APP_RETAILER_BASE_API;

const RETAILER_APIS = [
  // TODO temporarily use old api
  {
    pattern: '/bundles',
    reg: /^bundles(\?|$)/
  },
  {
    pattern: '/bundles/:id',
    reg: /^bundles\/\w+(\?|$)/
  },
  {
    pattern: '/bundles/:id/release',
    reg: /^bundles\/\w+\/release(\?|$)/
  },
  {
    pattern: '/payments/:id',
    reg: /^payments\/\w+(\?|$)/
  },
  {
    pattern: '/orders/?id',
    reg: /^orders\/\?id/
  }
];

const PAYMENT_METHOD_VALS = {
  credit: 1,
  paypal: 2,
  paypal_commerce: 3
};

function canSkipChange(config) {
  // switch to the new retailer api
  let url = config.url;
  url += `${url.includes('?') ? '&' : '?'}${new URLSearchParams(
    config.params
  ).toString()}`;

  const endpoint = getEndpoint(url);

  // not in whitelist
  if (RETAILER_APIS.every((apiConf) => !apiConf.reg.test(endpoint))) {
    return true;
  }

  return false;
}

function getEndpoint(url) {
  const DSZ_BASE_API = DSZ_API + 'rp/';
  const endpoint = url.replace(DSZ_BASE_API, '').replace(RETAILER_BASE_API, '');
  return endpoint;
}

// replace // to /
function replaceDoubleSlash(url) {
  return url.replace(/(?<!https?:)\/\//g, '/');
}

export function changeApi(config) {
  const url = replaceDoubleSlash(config.url);

  if (canSkipChange(config)) return url;

  const endpoint = getEndpoint(url);

  // change to call retailer api
  const newApi = replaceDoubleSlash(RETAILER_BASE_API + endpoint);

  return newApi;
}

export function changeReqData(config) {
  let { data, url } = config;
  if (canSkipChange(config)) return data;

  data = data || {};
  if (typeof data === 'string' && /^{.*}$|^\[.*\]$/.test(data)) {
    data = JSON.parse(data);
  }
  const endpoint = getEndpoint(url);

  if (/^bundles\/\w+(\?|$)/.test(endpoint)) {
    if (data.payment_method) {
      data.payment_method = PAYMENT_METHOD_VALS[data.payment_method];
    }
  }

  return data;
}

// res: {message, data, success} -> { message, result, success }
export function changeResDataKey(response) {
  const res = response.data;
  const url = response.config.url;
  const config = response.config;
  // switch to the new retailer api
  const endpoint = url.replace(RETAILER_BASE_API, '');
  // not in whitelist, use passing res
  if (canSkipChange(config || {})) {
    return;
  }

  // rename data key
  if (res.data !== null && res.data !== undefined) {
    res.result = res.data;
    delete res.data;
  }
}
