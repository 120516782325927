import React from 'react';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from 'reactstrap';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import {
  setSidebarToggle,
  setSidebarToggleMobile
} from '../../reducers/ThemeOptions';

import projectLogoSingleLine from '../../assets/images/logo-single.png';
import projectLogoText from '../../assets/images/logo-text.png';

import projectLogoTextNew from '../../assets/images/DSZ_favicon_307px.png';
import projectLogoTextPuple from '../../assets/images/DSZ_favicon_307px.png';

const SidebarHeader = (props) => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const {
    sidebarToggleMobile,
    setSidebarToggleMobile,
    sidebarToggle,
    setSidebarToggle,
    showFeatureItems5
  } = props;

  return (
    <>
      <div className="app-sidebar--header text-center">
        <div className="app-sidebar-logo">
          <a
            href={process.env.REACT_APP_DSZ_WEBSITE_URL}
            title="Dropshipzone"
            className="app-sidebar-logo">
            {!sidebarToggle && (
              <>
                {!showFeatureItems5 && (
                  <img alt="Sofortig" src={projectLogoSingleLine} />
                )}

                <img
                  style={{ paddingLeft: '90px' }}
                  alt="Dropshipzone"
                  src={showFeatureItems5 ? projectLogoTextNew : projectLogoText}
                  width={showFeatureItems5 ? '150' : ''}
                />
              </>
            )}
            {sidebarToggle && (
              <img
                alt="Vendor System"
                src={
                  showFeatureItems5
                    ? projectLogoTextPuple
                    : projectLogoSingleLine
                }
                width="50"
              />
            )}
          </a>
        </div>

        {/* <button
          onClick={toggleSidebar}
          className="btn btn-sm collapse-sidebar-btn"
          id="CollapseSidebarTooltip">
          <FontAwesomeIcon icon={['far', 'dot-circle']} size="lg" />
        </button> */}
        {/* <UncontrolledTooltip
          target="CollapseSidebarTooltip"
          container=".app-sidebar"
          placement="right">
          Collapse sidebar
        </UncontrolledTooltip> */}
        <button
          className={clsx(
            'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
            { 'is-active': sidebarToggleMobile }
          )}
          onClick={toggleSidebarMobile}>
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
        <button
          onClick={toggleSidebar}
          className="expand-sidebar-btn btn btn-sm"
          id="ExpandSidebarTooltip">
          <FontAwesomeIcon icon={['fas', 'arrows-alt-h']} />
        </button>
        <UncontrolledTooltip
          target="ExpandSidebarTooltip"
          container=".app-sidebar"
          placement="right">
          Expand sidebar
        </UncontrolledTooltip>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  showFeatureItems5: state.ThemeOptions.showFeatureItems5
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
